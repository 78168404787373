.aboupage-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:50px;
}
.aboupage-container-overall{
    margin-top: 10px;
   /*  display: grid;
    grid-template-columns: repeat(auto-fit,minmax(260px,1fr)); */
    width: 100%;
} 


.aboutus-head{
    font-size: 40px;
    font-weight: 600;
     color: #28A745;
}
.aboutus-para-one{
    font-size: 30px;
    font-weight: 600;
    color: #1239AB;
    text-align: center;
}
.about-long-para{
    font-size: 20px;
    font-weight: 400;
}
.contact-btn button{
    width: 220px;
   height: 50px;
   border-radius: 20px;
   background-color: #28A745;
   border: none;
   outline: none;
   font-size: 20px;
   font-weight: 500;
   color: white;
   margin-top: 20px;
   cursor: pointer;
}
.aboupage-right{
    float:right;
    width:50%;
    text-align: center;
    margin-top: 20px;
}
.aboupage-left{
    float:left;
     width: 50%;
     padding: 20px;
}


.ewast-auto-img{
    width:100%;
}
.contact-btn button:hover{
    background-color: #1239AB;
}

@media screen  and (max-width:990px){
    .aboupage-right{
        float:right;
        width: 100%;
    }
    .aboupage-left{
        float:left;
        width: 100%;
    }
    
}