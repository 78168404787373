.work-overall{
    padding-top:40px;
    padding-bottom:60px; 
    display: flex;
    justify-content: center;
}
.work-procss-container{
    background-image: url(../assert/vector.png);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover; 
    width: 90%;
   
}
.work-procss-container h1{
    font-size: 40px;
    font-weight: 600;
    color: #28A745; 
    text-align: center; 
}
.work-procss-container p{
font-size: 30px;
font-weight: 500;
color: #1239AB;
text-align: center;
/* margin-top: 15px; */
}
.three-types{
    display: flex;
    justify-content: center;
    gap:300px;
    margin-top: 20px; 
  
}
.work-small,.work-big{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.work-small img{
    width: 180px;
    height: 188px;
    border-radius: 50%;
    
}
.work-big{
    height: 100%;
}
.work-big img{
    border-radius:50%;
    width: 300px;
    
}

.work-small{
 
    height:100%;
    margin-top:100px;
    margin-right:40px;
}













 @media screen and (max-width:1100px) {
    .three-types{
        /*   display: grid;
          grid-template-columns: repeat(auto-fit,minmax(300px,1fr)); */
          display: flex;
           flex-direction: column;
           gap: 10px;
      }
      .work-small{
        margin-right:10px;
        height:100%;
        margin-top:10px;
    }
    .work-procss-container{
        background-image: none;
       
    }
 }