@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.nav-bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 20px;
    color: black;
   
}
.ultag{
    display: flex;
    gap: 50px;
}
.nav-bar ul a li{
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
.nav-bar ul a{
    text-decoration: none;
}
.company-logo{
    width: 213.44px;
    height: 59.12px
}
.reqvest-btn{
   padding: 10.02px;
   border-radius: 18.04px;
   gap: 10.02px;
   background: #28A745;
   color: white;
   font-weight: 700;
   cursor: pointer;
   border: none;
   outline: none;
 
}
.contact-ph-email{
    text-align: center;
}
.nav-phone-number{
    color: #1239ABEB;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 700;
    line-height: 23px;
}
.contact-ph-email a{
     text-decoration: none;
}
.nav-email{
    color: #28A745;
    font-weight: 700;
    font-size: 15px;
}
.hidden-btn{
    display: none;
} 
.nav-active{
    text-decoration:underline;
   color: #28A745; 
}
.reqvest-btn:hover{
    background-color:  #1239ABEB;
}

@media screen and (max-width:1000px) {
    .hidden-btn{
        display:block;
        border: none;
        outline: none;
        font-size:20px;
        padding:5px;
        background-color: #28A745;
    }
   .nav-bar{
    flex-direction: column;
   }
   .ultag{
    display: none;
   }
   .ultag-view{
    display: flex;
    flex-direction: column;
    gap:15px;
    align-items: center;
    text-align:center ;
    margin-top: 15px;
   }
    .log-icons{
        width: 100vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-repeat: no-repeat;
       /*  background:linear-gradient(to right,rgba(97, 96, 96, 0.297), rgba(92, 93, 92, 0.17))   */ 
    }
    
}