.map-container{
    background:linear-gradient(rgba(128, 128, 128, 0.348),rgba(128, 128, 128, 0.337)),
    url(../assert/Emptydespin.jpg);
    padding: 40px;
   
}
.iframe-detials-left{
    width: 50%;
    float: left;
}
.despin-boy-img-right{
    width: 50%;
    float:right;
 
}
iframe{
    width: 600px;
    height: 400px;
    float: right;
}
.emptyfooder-img{
   height:350px;
   float: right;
}


@media screen and (max-width:900px) {
    .despin-boy-img-right,.iframe-detials-left{
        width: 100%;
    }
    .emptyfooder-img,iframe{
       width:100% ;
       margin-top: 15px;
     }
}