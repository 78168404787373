.contact-form-container{
    display: flex;
    justify-content: center;
    align-items: center;  
    padding-bottom: 20px;
}
.contact-form-detials{
    width: 90%;
    margin-top:20px;
}
.contact-form-left{
    width: 50%;
    float: left;
    padding: 10px;
}
.contact-form-right{
    width: 50%;
    float: right;
    padding: 20px;
}
.contact-form-left{
    display: flex;
    flex-direction: column;
    gap:30px;
}
.icon-detials{
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.contact-detial .con-title{
   font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
}
.contact-detial .con-detial{
    font-size:18px;
    font-weight: 400;
    line-height: 30px; 
    color: gray;
}
.con-icons{
     color: white;
     font-size:50px;
     padding: 10px;
     border-radius: 50%;
     background-color: #28A745;
   
}
.forany{
font-size: 30px;
font-weight: 500;
line-height: 45px;
letter-spacing: 0em;
text-align: center;

}
.contactus-title{
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    color: #28A745;
}
.touch-para{
  font-size: 20px;
  font-weight: 300;
  color:  #1239AB;
  text-align: center;
  padding-bottom: 20px;
}
.left-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px; 
}











.forms-con{
    padding: 10px;
}
.con-btns button:hover{
   background-color: #1239AB;
   cursor: pointer;
}


.form-inp input{
    width:380px;
    height:60px;
    padding:10px;
    border: 1px solid rgba(128, 128, 128, 0.225);
    outline: none;
    font-size: 20px;
    box-shadow: 0px 0px 10px   #00000040;
    
 
}

.form-inp{
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.name-service{
    width: 50%;
    float: left;
}
.email-phone{
   width: 50%;
   float: right;
 
}
.messg{
    width:665px;
    height: 150px;
    margin-top: 20px;
    border: 1px solid rgba(128, 128, 128, 0.225);
    outline: none;
    box-shadow: 0px 0px 10px gray;
    resize: none;
    padding: 10px;
}
.form-inp input::placeholder,.messg{
   font-size: 20px;
   font-weight: 400;
   color: black;
}
.con-btns{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.con-btns button{
width: 192px;
height: 50px;
border-radius: 20px;
background-color: #28A745;
font-size: 20px;
font-weight: 700;
color: white;
border: none;
outline: none;
}





@media screen and (max-width:1000px) {
   
    .name-service,.email-phone{
        width:100%;
        margin-top: 10px;
    }
    .contact-form-left,.contact-form-right{
        width:100%;
         
    }
    .form-inp input{
        width:100%;
    }
    .messg{
        width: 100%;
    }
}