@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    font-family: "Poppins", sans-serif;
}
.carsoul-con{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.carsoul-con h1{
    font-size: 50px;
    color: #28A745;
    text-shadow: 0px 0px 10px black;
}
.carsoul-con h3{
    font-size: 30px;
    width: 700;
    color: white;
}
.reqvest-btns-car{
    width: auto;
}
.reqvest-btns-car button{
    background-color: #28A745;
    border: none;
    outline: none;
    height: 50px;
    border-radius: 20px;
    color: white;
    font-weight:700;
    width:30%;
    margin-top: 10px;
    cursor: pointer;
    
}
.reqvest-btns-car button:hover{
    background-color:  #1239ABEB;
}