.main-container-poplur{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:40px;
    padding-bottom: 40px;
}
.con-pop{
    width: 90%;  
}
.pop-detial img{
    width: 120px;
    height:120px;
    padding:20px;
    background: #EDF3FB;
    border-radius: 50%;
}
.pop-detial-container{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px,1fr));
}
.pop-img{
    display: flex;
    justify-content: center;
    align-items: center;
}
.pop-detial{
    padding: 20px;
    margin:20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 0px 10px gray;
    cursor: pointer;
}
.pop-title{
    font-size: 25px;
    font-weight: 500;
    text-align: center;
}
.pop-para-con{
  display: flex;
  justify-content: center;
}
.pop-para{
    width:400px;
    font-size: 20px;
    font-weight: 400;
    color: #484848;
    text-align: center;
}
.con-pop h1{
    font-size: 40px;
    font-weight: 600;
    color: #019A34;
    text-align: center;
}
.our-most{
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #1239AB;
    margin-top: 10px;
}
.pop-detial:hover{
    background: #019A34;
    transition: all 0.5s;
}










@media screen and (max-width:900px) {
    .pop-detial-container{
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    }
    .pop-para{
        width:600px;
    } 
}