.main-experienc{
    background-image: url(../assert//u-shap.png);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
}
.experience-total{
    width: 90%;
    display: flex;
    align-items: end;
    gap:40px;
    justify-content: center;
}
.exp-detials{
    /* display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
     */
     display: flex;
     gap:60px;
     margin-bottom: 60px;
}
.exp-num h1{
  font-size: 80px;
font-weight: 700;
line-height: 120px;
 text-align: center;
 color: white;
}
.exp-num h1 sup{
    width: 29px;
    color:  #FFF500;
}
.exp-num p{
   font-size: 20px;
   font-weight: 600;
   text-align: center;
   color:  #1239AB;
}
.ladey-img img{
    float:right;
 
}

@media screen and (max-width:900px) {
    .exp-detials,.experience-total{
        flex-direction: column;
        align-items: center;
    }
    .main-experienc{
       background-image: none;
    }
    .experience-total{
        background-color: darkgreen;
    }
    .ladey-img{
        display: none;
    }
}