.reqvest-pickup-con{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    
}
.reqvest-pickup{
    padding:20px;
    margin-top: 20px;
}
.pic-up-forms{
    margin-top: 20px;
}
.picup-right{
   width: 50%;
   float: left;
}
.picup-left{
    width: 50%;
    float: left;
}
.reqvest-pickup{
    display: flex;
    flex-direction:column ;
    align-items: center;
}
.pick-btns{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.pick-btns button{
    background-color: darkgreen;
    color: white;
    font-size:20    px;
    font-weight:600;
    border: none;
    outline: none;
    border-radius: 20px;
    padding:10px;
    cursor: pointer;
    width: 150px;
}
.pic-flex input{
   padding: 10px;
   font-size: 15px;
   box-shadow: 0px 0px 10px gray;
   border: none;
}
 
.pic-flex label{
    font-size: 15px;
    font-weight: 400;
}


.pic-flex{
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.pic-flex input{
    width: 350px;
    height:60px;
}


@media screen  and (max-width:900px){
    .picup-right,.picup-left{
        width:100%;
     }
     .pic-flex input{
        width:100%;
    }
}