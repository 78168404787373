.service-all-contant-con{
    background-color: forestgreen;
    display: flex;
    justify-content: center;
    align-items: center;
     padding-top: 40px;
     padding-bottom: 40px;
}
.services-container{
    width: 90%;
    margin: 15px;
}
.service-left{
    width: 50%;
    float: left;  
}
.serve-left-contant{
    width: 50%;
    float: left;
    line-height:50px;
}
.serve-right-contant{
  
   width: 50%;
   float: right;
   line-height:50px;
}
.service-right{
    width: 50%;
    float: right; 
     overflow: hidden;
}
.gap-con p{
    font-size: 20px;
    font-weight: 600;
    color:  #1239AB;
}
.tick{
    color: yellow;
    margin-right:20px;
    font-size:30px;
}
.service-left h1{
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
}
.expert{
 display: flex;
 justify-content: center;
}
.expert p{
    width: 600px;
    font-size: 20px;
    font-weight:500;
    color: white;
}
.container-for-left-right,.expert{
    margin-top:20px;
}
.common-center-service{
   display: grid;
    place-items: center;
    text-align: justify; 
}


/*  img */
.serve-two-img{
    display: flex;
   justify-content: center;  
   align-items: center;
   pointer-events: none;
 
}



@media screen and (max-width:750px) {
    .serve-left-contant,.serve-right-contant{
           width: 100%;    
    }
    
}

@media screen and (max-width:950px) {
    .service-left,.service-right{
        width: 100%;   
    }
}