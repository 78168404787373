.e-wast-container{
    background-color: #ECECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.overall-container-ewast{
     padding-top: 30px;
     padding-bottom: 20px; 
        width:85%;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(280px,1fr));
    }
.ewast-right{
   display: flex;
   justify-content: center;
 
}
.ewast-right img{
    width: 400px;
    height: 300px;
}
.e-wast-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
}
.prefect-para{
    font-size: 30px;
    font-weight:600;
}
.prefect-para span{
    font-size: 40px;
    font-weight: 900;
    color: #1239AB;
}
.e-wast-para{
    font-size: 20px;
    font-weight: 400;
    width: 663px;
}
.e-wast-para-con{
    display: flex;
    justify-content: center;
}
.ewast-btn button{
    width: 220px;
    height: 50px;
    border-radius: 20px;
    background-color: #28A745;
    font-size: 20px;
    font-weight: 500;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
}
.ewast-btn button:hover{
    background-color:  #1239ABEB;
}