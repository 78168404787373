.fooder-overall-container{
    background-color: darkgreen;
    padding:30PX;
}
.fooder-titles{
    font-size: 25px;
    font-weight: 600;
    color: #090909;
}
.quick-ultage li,.service-ultag li{
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-top: 10px;
}
.fooder-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(320px,1fr));
}
.opening-hours,.quick-link{
    border-right:2px solid black;
}
.opening-hours,.quick-link,.services-fooder,.logo-description{
    padding: 20px;
   margin-top: 10px;
}
.quick-ultage ,.service-ultag{
    margin-top: 20px;
}
.icon-fooder-num,.icon-fooder-email{
    display: flex ;
    gap:10px; 
}
.icon-fooder-email p,.icon-fooder-num p{
    font-size:15px;
    font-weight: 500;
    color: white;
  
}
 
.mobile-num-ic{
    color: black;
    font-size: 40px;
}
.opening-hours{
    display: flex;
    flex-direction: column;
    gap:30px;
 
}

.open-time{
 color: white;    
 font-size: 20px;
 font-weight: 500;
 
}
.insta-fac-whats{
    display: flex;
    gap: 30px;
    margin-top: 20px;
}
.i-f-w-icon{
    color:#0000009e;
    font-size: 40px;
    cursor: pointer;
}
.icon-fooder-num a,.icon-fooder-email a{
    text-decoration: none;
     
}
.fooder-logo{
    width: 100%;
}